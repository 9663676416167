import React, { Component } from 'react';
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { LIGHTER_GREY, WHITE } from '@parasightsysteminc/companion-react';
import { updateInventoryTypeCount, updateOrderTriggerNumberCounts, listPracticeInventoryTypes, listInventoryTypeSkus } from '../api';
import { setError } from '../alerts';
import { AlertDialog } from './';

class InventoryWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      practiceInventoryTypes: [],
      openFluid: false,
      openThresholds: false,
      dialogFluid: 0,
      count: 0,
      lowInventoryCount: [],
      inManualMode: false,
      inScanMode: false,
      scanValue: '',
      inventoryTypeSkus: null,
      errorMessage: null,
      successMessage: null
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    listPracticeInventoryTypes().then(results => {
      listInventoryTypeSkus().then(inventoryTypeSkus => {
        this.setState({ practiceInventoryTypes: results, isLoading: false, inventoryTypeSkus });
      }).catch(error => {
        setError(error ?? 'Error: Unable to retrieve inventory type skus. Please try again.');
        this.setState({ isLoading: false });
      });

      this.setInventoryFluidThresholds(results);
    }).catch(error => { //Catch inventory error
      setError(error ?? 'Error: Unable to retrieve inventory. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  onAlertClose = () => {
    this.setState({ successMessage: null, errorMessage: null }, () => {
      this.inputRef.current?.focus();
    });
  }

  setInventoryFluidThresholds(practiceInventoryTypes) {
    let holder = [];
    practiceInventoryTypes.map(practiceInventoryType => {
      holder = [...holder, {
        inventoryTypeId: practiceInventoryType.getInventoryTypeId(),
        orderTriggerNumber: practiceInventoryType.getOrderTriggerNumber()
      }];
    });
    this.setState({ lowInventoryCount: holder });
  }

  // Fluid Bag modal functions (open, add bag, sub bag, close/submit)
  setOpenFluid = (inventoryTypeId) => {
    this.setState({ openFluid: true });
    let practiceInventoryTypeIndex = this.state.practiceInventoryTypes.findIndex(practiceInventoryType => practiceInventoryType.getInventoryTypeId() === inventoryTypeId);
    this.setState({ dialogFluid: inventoryTypeId, count: this.state.practiceInventoryTypes[practiceInventoryTypeIndex].getCount() });
  }

  changeInventoryQuantity = (delta) => {
    let newCount = this.state.count + delta;
    if (newCount < 0) newCount = 0;
    this.setState({ count: newCount });
  }

  setCloseFluid(save)  {
    if (save) {
      this.setState({ isLoading: true });
      updateInventoryTypeCount({ inventoryTypeId: this.state.dialogFluid, count: this.state.count }).then(inventory => {
        this.setState({ isLoading: false, practiceInventoryTypes: inventory });
      }).catch(error => { //Catch instrument fluid error
        setError(error ?? 'Error: Unable to update fluid bag count. Please try again.');
        this.setState({ isLoading: false });
      });
    }
    this.setState({ openFluid: false });
  }

  // Fluid threshold modal functions (open, increase, decrease, close/submit)
  setOpenThresholds = () => {
    this.setState({ openThresholds: true });
  }

  increaseOrderTriggerNumber(practiceInventoryType) {
    let practiceInventoryTypeIndex = this.state.practiceInventoryTypes.findIndex(type => type.getInventoryTypeId() === practiceInventoryType.getInventoryTypeId());
    let orderTriggerNumbers = this.state.lowInventoryCount;
    let orderTriggerNumber = this.state.lowInventoryCount[practiceInventoryTypeIndex].orderTriggerNumber;
    let newOrderTriggerNumber = practiceInventoryType.getInventoryType().getIsTestKit() ? orderTriggerNumber + 10 : orderTriggerNumber + 1;
    orderTriggerNumbers.splice(practiceInventoryTypeIndex, 1, { inventoryTypeId: practiceInventoryType.getInventoryTypeId(), orderTriggerNumber: newOrderTriggerNumber });
    this.setState({ lowInventoryCount: orderTriggerNumbers });
  }

  decreaseOrderTriggerNumber(practiceInventoryType) {
    let practiceInventoryTypeIndex = this.state.practiceInventoryTypes.findIndex(type => type.getInventoryTypeId() === practiceInventoryType.getInventoryTypeId());
    let orderTriggerNumbers = this.state.lowInventoryCount;
    if (orderTriggerNumbers[practiceInventoryTypeIndex]?.orderTriggerNumber > practiceInventoryType.getInventoryType()?.getMinimumOrderTriggerNumber()) {
      let orderTriggerNumber = this.state.lowInventoryCount[practiceInventoryTypeIndex].orderTriggerNumber;
      let newOrderTriggerNumber = practiceInventoryType.getInventoryType().getIsTestKit() ? orderTriggerNumber - 10 : orderTriggerNumber - 1;
      orderTriggerNumbers.splice(practiceInventoryTypeIndex, 1, { inventoryTypeId: practiceInventoryType.getInventoryTypeId(), orderTriggerNumber: newOrderTriggerNumber });
    }
    this.setState({ lowInventoryCount: orderTriggerNumbers });
  }

  setCloseThresholds(save) {
    if (save) {
      this.setState({ isLoading: true });
      updateOrderTriggerNumberCounts(this.state.lowInventoryCount).then(inventory => {
        this.setState({ isLoading: false, practiceInventoryTypes: inventory });
      }).catch(error => {
        setError(error ?? 'Error: Unable to update threshold values. Please try again.');
        this.setState({ isLoading: false });
      });
    }
    else {
      this.setInventoryFluidThresholds(this.state.practiceInventoryTypes);
    }
    this.setState({ openThresholds: false });
  }

  // Row data for each practice inventory type in low threshold modal
  orderTriggerNumberRow(practiceInventoryType) {
    let practiceInventoryTypeIndex = this.state.practiceInventoryTypes.findIndex(type => type.getInventoryTypeId() === practiceInventoryType.getInventoryTypeId());
    return (
      <Container key={practiceInventoryType.getId()} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '.5em' }}>
        <div style={{ width: '35%', paddingRight: '10px' }}>
          <DialogContentText>{practiceInventoryType.getInventoryType()?.getName()}</DialogContentText>
        </div>
        <div style={{ width: '65%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Button color='secondary' disabled={this.state.isLoading || this.state.lowInventoryCount?.find(obj => obj.inventoryTypeId === practiceInventoryType.getInventoryTypeId())?.orderTriggerNumber <= practiceInventoryType.getInventoryType()?.getMinimumOrderTriggerNumber()} onClick={() => {
            this.decreaseOrderTriggerNumber(practiceInventoryType);
          }} variant='outlined' style={{ borderRadius: '20%', marginRight: '.5em' }}>-</Button>
          <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography style={{ marginLeft: '1em', marginRight: '1em', fontWeight: 'bold' }}>{this.state.lowInventoryCount[practiceInventoryTypeIndex]?.orderTriggerNumber}</Typography>
          </div>
          <Button color='secondary' disabled={this.state.isLoading} onClick={() => {
            this.increaseOrderTriggerNumber(practiceInventoryType);
          }} variant='outlined' style={{ borderRadius: '20%', marginLeft: '.5em' }}>+</Button>
        </div>
      </Container>
    );
  }

  addInventoryFromScan = (e) => {
    e.preventDefault();

    this.setState({ scanValue: '' });

    if (this.state.scanValue == null) {
      this.setState({ errorMessage: 'Error Invalid barcode. Please try again.' });
      return;
    }

    // Strip the leading numbers from the barcode
    const numericPrefixMatch = this.state.scanValue.match(/^\d+/);
    const numericPrefix = numericPrefixMatch ? numericPrefixMatch[0] : '';
    const sku = this.state.scanValue.substring(numericPrefix.length)?.toUpperCase() ?? '';

    if (sku === '') {
      this.setState({ errorMessage: 'Error: Invalid barcode. Please try again.' });
      return;
    }

    let year, month, date;
    if (numericPrefix !== '') {
      year = numericPrefix.length >= 2 ? numericPrefix.substring(0, 2) : undefined;
      month = numericPrefix.length >= 4 ? numericPrefix.substring(2, 4) : undefined;
      date = numericPrefix.length >= 6 ? numericPrefix.substring(4, 6) : undefined;
    }

    // Find inventory type sku
    let inventoryTypeSku = this.state.inventoryTypeSkus.find(inventoryTypeSku => inventoryTypeSku.getSku() === sku);

    if (inventoryTypeSku == null) {
      this.setState({ errorMessage: 'Error: Unable to find inventory type sku. Please try again.' });
      return;
    }

    // Check to ensure inventory type sku is for an inventory type that is visible to user
    let practiceInventoryType = this.state.practiceInventoryTypes.find(practiceInventoryType => practiceInventoryType.getInventoryTypeId() === inventoryTypeSku.getInventoryTypeId());
    if (practiceInventoryType == null) {
      this.setState({ errorMessage: 'Error: Unable to update inventory type. Please try again.' });
      return;
    }

    if (year != null) {
      // LOL ... this is a problem for Y3K
      let bornOnDate = new Date(year ? '20' + year : 2025, month ? month - 1 : 0, date ? date : 1);

      let shelfLifeDays = inventoryTypeSku.getInventoryType()?.getShelfLifeDays();
      if (shelfLifeDays == null) {
        this.setState({ errorMessage: 'Error: Unable to find shelf life days. Please try again.' });
        return;
      }

      // Check if expired based on bornOnDate and shelfLifeDays
      let expirationDate = new Date(bornOnDate);
      expirationDate.setDate(expirationDate.getDate() + shelfLifeDays);

      if (expirationDate < new Date()) {
        this.setState({ errorMessage: 'Error: Inventory is expired. Please contact Parasight at info@parasightsystem.com to request a replacement.' });
        return;
      }
    }

    let inventoryTypeIndex = this.state.practiceInventoryTypes.findIndex(practiceInventoryType => practiceInventoryType.getInventoryTypeId() === inventoryTypeSku.getInventoryTypeId());
    let newCount = this.state.practiceInventoryTypes[inventoryTypeIndex].getCount() + inventoryTypeSku.getQuantity();
    updateInventoryTypeCount({ inventoryTypeId: inventoryTypeSku.getInventoryTypeId(), count: newCount }).then(inventory => {
      this.setState({ practiceInventoryTypes: inventory, isLoading: false, successMessage: `Successfully added ${inventoryTypeSku.getQuantity()} ${inventoryTypeSku.getInventoryType().getName()}${inventoryTypeSku.getQuantity() > 1 ? 's' : ''}!` });
    }).catch(error => {
      this.setState({ isLoading: false, errorMessage: error ?? 'Error: Unable to update inventory. Please try again.' });
    });
  }

  // practiceInventoryType row in main widget
  inventoryTypeRow(practiceInventoryType) {
    let color = 'green';
    let remainingInventory = practiceInventoryType.getCount() - practiceInventoryType.getOrderTriggerNumber();
    if (remainingInventory < 0) color = 'red';
    else if (practiceInventoryType.getInventoryType()?.getIsTestKit() ? (remainingInventory <= 50) : (remainingInventory <= 0)) color = 'gold'; 
    return (
      <Box key={practiceInventoryType.getId()} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems:'center', backgroundColor: color, borderRadius: '5px', padding: '1em', margin: '1em' }}>
        <Grid container>
          <Grid item xs={6} sm={6} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
            <Typography style={{ color: WHITE, textAlign: 'center', fontWeight: 'bolder' }}>
              {practiceInventoryType.getInventoryType()?.getName()}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
            <Typography style={{ color: WHITE, textAlign: 'center', fontWeight: 'bold' }}>
              {practiceInventoryType.getCount()} {practiceInventoryType.getCount() === 1 ? practiceInventoryType.getInventoryType()?.getUnit() : practiceInventoryType.getInventoryType()?.getPluralUnit()}
            </Typography>
          </Grid>
        </Grid>
        {this.state.inManualMode &&
        <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Button disabled={this.state.isLoading} variant='contained' onClick={() => {
            this.setOpenFluid(practiceInventoryType.getInventoryTypeId());
          }} style={{ backgroundColor: LIGHTER_GREY, float: 'right' }}>
            Add/Remove
          </Button>
        </Box>}
      </Box>
    );
  }

  render() {
    return (
      <Container>
        <AlertDialog
          message={this.state.errorMessage ?? this.state.successMessage}
          type={this.state.errorMessage != null ? 'ERROR' : this.state.successMessage != null ? 'SUCCESS' : null}
          onClose={this.onAlertClose}
        />

        {/* Scanning Mode */}
        {!this.state.inManualMode &&
        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '20px', marginBottom: '40px' }}>
          {this.state.inScanMode ? (
            <form onSubmit={this.addInventoryFromScan} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <TextField
                label='Barcode Value'
                fullWidth
                value={this.state.scanValue}
                onChange={e => this.setState({ scanValue: e.target.value?.toUpperCase() ?? e.target.value })}
                variant='filled'
                disabled={this.state.isLoading || this.state.errorMessage != null || this.state.successMessage != null}
                required
                style={{ width: '300px' }}
                autoFocus
                ref={this.inputRef} />

              <Button variant='contained' type='submit' style={{ marginTop: '1em' }}>Add Inventory</Button>
            </form>
          ) : (
            <Button variant='contained' color='secondary' onClick={() => this.setState({ inScanMode: true, inManualMode: false })}>Scan New Inventory</Button>
          )}
        </Box>}

        {/* Main Widget */}
        {this.state.practiceInventoryTypes.map(practiceInventoryType => {
          return (this.inventoryTypeRow(practiceInventoryType));
        })}

        {/* Buttons */}
        <Box textAlign='center'>
          <Button disabled={this.state.isLoading} variant='outlined' onClick={() => this.setState({ inManualMode: !this.state.inManualMode, inScanMode: false })} style={{ margin: '1em' }}>{this.state.inManualMode ? 'Scan Mode' : 'Manual Edit'}</Button>
          <Button disabled={this.state.isLoading} variant='outlined' onClick={this.setOpenThresholds} style={{ margin: '1em' }}>Manage Inventory Thresholds</Button>
        </Box>

        {/* Bag Counter Modal */}
        <Dialog open={this.state.openFluid} onClose={() => this.setCloseFluid(false)}>
          <DialogTitle textAlign='center'>{this.state.practiceInventoryTypes[this.state.dialogFluid-1]?.getInventoryType()?.getName()} Inventory</DialogTitle>
          <DialogContent>
            <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Button color='secondary' disabled={this.state.isLoading || this.state.count === 0} onClick={() => {
                this.changeInventoryQuantity(-50);
              }} variant='contained' style={{ borderRadius: '20%', marginRight: '.5em' }}>- 50</Button>
              <Button color='secondary' disabled={this.state.isLoading || this.state.count === 0} onClick={() => {
                this.changeInventoryQuantity(-25);
              }} variant='contained' style={{ borderRadius: '20%', marginRight: '.5em' }}>- 25</Button>
              <Button color='secondary' disabled={this.state.isLoading || this.state.count === 0} onClick={() => {
                this.changeInventoryQuantity(-1);
              }} variant='contained' style={{ borderRadius: '20%', marginRight: '.5em' }}>- 1</Button>
              <Typography style={{ margin: '1em' }}>{this.state.count}</Typography>
              <Button color='secondary' disabled={this.state.isLoading} onClick={() => {
                this.changeInventoryQuantity(1);
              }} variant='contained' style={{ borderRadius: '20%', marginLeft: '.5em' }}>+ 1</Button>
              <Button color='secondary' disabled={this.state.isLoading} onClick={() => {
                this.changeInventoryQuantity(25);
              }} variant='contained' style={{ borderRadius: '20%', marginLeft: '.5em' }}>+ 25</Button>
              <Button color='secondary' disabled={this.state.isLoading} onClick={() => {
                this.changeInventoryQuantity(50);
              }} variant='contained' style={{ borderRadius: '20%', marginLeft: '.5em' }}>+ 50</Button>
            </Container>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', marginBottom: '1em' }}>
            <Button disabled={this.state.isLoading} onClick={() => this.setCloseFluid(true)} variant='contained' style={{ marginTop: '.5em' }}>{this.state.isLoading && <CircularProgress />} Save</Button>
          </DialogActions>
        </Dialog>

        {/* Low Threshold Modal */}
        <Dialog open={this.state.openThresholds} onClose={() => this.setCloseThresholds(false)}>
          <DialogTitle textAlign='center'>Low Inventory Alert Threshold</DialogTitle>
          <DialogContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: 'fit-content' }}>
            <Container style={{ display: 'flex', flexDirection: 'column' }}>
              {this.state.practiceInventoryTypes.map(practiceInventoryType => {
                return this.orderTriggerNumberRow(practiceInventoryType);
              })}
            </Container>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', marginBottom: '1em' }}>
            <Button disabled={this.state.isLoading} onClick={() => this.setCloseThresholds(true)} variant='contained' style={{ marginTop: '.5em' }}>{this.state.isLoading && <CircularProgress />} Save</Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

export default (InventoryWidget);
