import React, { Component } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Autocomplete, Box, FormControlLabel, Checkbox, TextField, Accordion, AccordionSummary, AccordionDetails, Container, Typography, CircularProgress } from '@mui/material';
import ImageViewer from 'react-simple-image-viewer';

import { Button, BackToButton, LineItem } from '@lexcelon/react-util';

import { setError, setSuccess } from '../../../alerts';

import { getTest, listTreatments, submitTestRecommendations, getPractice, sendTestToPims } from '../../../api';

import { ExpandMore } from '@mui/icons-material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { closeConfirm, confirm } from '../../../alerts/confirm';
import cookies from '../../../cookies';

const INITIAL_STATE = {
  test: null,
  parentTest: null,
  imageViewerSource: null,
  isOtherImageViewerOpen: false,
  treatments: [],
  selectedTreatments: [],
  notes: '',
  isLoading: false,
  sendResultsToOwner: true,
  inViewFoundParasites: [],
  inEditRecommendations: false,
  isLoadingTest: true,
  resultsTests: [],
  isLoadingPimsSend: false,
  redirectToTestId: null,
};

class Test extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development' || (process.env.NODE_ENV === 'development' && this.hasMountedAlready === true /* see note below */)) {
      let testId = this.props.match?.params?.id;
      getTest(testId).then(test => {
        this.setState({ test, isLoadingTest: false });

        // Retrieve list of treatments
        if (test.getStatus() === 'COMPLETED') {
          listTreatments({ animalSpeciesId: test.getAnimal()?.getAnimalSpeciesId() }).then(({ results: treatments }) => {
            this.setState({ treatments });
          }).catch(error => {
            setError(error ?? 'Error: Unable to retrieve treatments. Please reload the page to try again.');
          });
        }

        // If not the ultimate parent test, redirect to the ultimate parent test
        if (test.getParentTestId() != null || test.getJointParentTestId() != null) {
          this.setState({ redirectToTestId: test.getJointParentTestId() ?? test.getParentTestId() });
          return;
        }
        
        // Retrieve all child tests
        const testIds = [];
        if (test.getJointChildTests() != null && test.getJointChildTests().length > 0) {
          test.getJointChildTests().forEach(jointTest => {
            if (jointTest.getChildTests()?.length > 0) {
              jointTest.getChildTests().forEach(childTest => testIds.push(childTest.getId()));
            }
            else testIds.push(jointTest.getId());
          });
        }
        else if (test.getChildTests()?.length > 0) {
          test.getChildTests().forEach(childTest => testIds.push(childTest.getId()));
        }

        if (testIds.length === 0) this.setState({ resultsTests: [test] });
        else {
          const promises = testIds.map(testId => getTest(testId));
          Promise.all(promises).then(tests => {
            this.setState({ resultsTests: tests });
          }).catch(error => console.log(error));
        }
      }).catch(error => {
        setError(error ?? 'Error: Unable to retrieve test. Please try again.');
        this.setState({ redirect: true });
      });

      getPractice().then(practice => {
        this.setState({ sendResultsToOwner: practice.getSendResultsToOwner() ?? false });
      }).catch(error => console.log(error));
    }

    /*
    * In dev mode, React.StrictMode renders everything twice, which is a huge problem when
    * we're talking about a connection to the instrument. To accommodate that without having
    * to get rid of React.StrictMode altogether (it is not granular), we check in development
    * mode to see if this is the second render before connecting to the websockets
    */
    this.hasMountedAlready = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.match?.params?.id !== prevProps.match?.params?.id) {
      this.setState(INITIAL_STATE, () => {
        this.componentDidMount();
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmitRecommendations = (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });
    const { selectedTreatments, notes, sendResultsToOwner } = this.state;
    submitTestRecommendations(this.props.match?.params?.id, { treatmentIds: selectedTreatments?.map(treatment => treatment.getId()), notes: notes !== '' ? notes : null, sendResultsToOwner }).then(({ test, error }) => {
      this.setState({ isLoading: false, test, inEditRecommendations: false });
      setSuccess('Successfully updated recommendations!');
      if (error) setError(error);
    }).catch(error => {
      setError(error ?? 'Error: Unable to submit your recommendations. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  openEditRecommendations = () => {
    if (this.state.test == null) return;
    let selectedTreatments = [];
    for (const treatment of this.state.test.getTreatments()) {
      let foundTreatment = this.state.treatments.find(t => t.getId() === treatment.getId());
      if (foundTreatment != null) selectedTreatments.push(foundTreatment);
    }

    this.setState({ inEditRecommendations: true, notes: this.state.test.getNotes() ?? '', selectedTreatments: selectedTreatments });
  }

  onLinkedTestPress = (to) => {
    if ((this.state.test?.getTreatments()?.length === 0 && (this.state.notes !== '' || this.state.selectedTreatments.length > 0)) || this.state.inEditRecommendations) {
      confirm({
        title: 'Are you sure you want to leave this page?',
        body: 'Any recommendation changes you have made will be lost.',
        onConfirm: () => {
          closeConfirm();
          this.props.history.push(to);
        },
        danger: true
      });
    }
    else this.props.history.push(to);
  }

  onSendToPims = () => {
    if (this.state.test == null) return;
    
    this.setState({ isLoadingPimsSend: true });
    sendTestToPims(this.state.test.getId()).then(() => {
      setSuccess('Successfully sent test PDF to PIMS!');
    }).catch(error => {
      setError(error ?? 'Error: Unable to send test PDF to PIMS. Please try again.');
    }).finally(() => this.setState({ isLoadingPimsSend: false }));
  }

  render() {
    // Loading Icon
    if (this.state.isLoadingTest) return (
      <Container style={{ paddingTop: '20px', paddingBottom: '30px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );

    // Still in progress test
    else if (this.state.test?.getStatus() === 'IN_PROGRESS' || this.state.test?.getStatus() === 'CREATED') return <Redirect to={`/tests/${this.props.match?.params?.id}/in-progress`} />;
    
    // Error or cancelled (or unknown) status
    else if (this.state.test != null && this.state.test.getStatus() !== 'COMPLETED') {
      if (this.state.test.getStatus() != null) setError(`Error: This test is ${this.state.test.getStatus()}`);
      return <Redirect to={this.props.location?.state?.backTo ?? '/tests'} />;
    }

    // Redirect to specific test
    else if (this.state.redirectToTestId != null) return <Redirect to={`/tests/${this.state.redirectToTestId}`} />;

    // On error redirect
    else if (this.state.redirect) return <Redirect to={this.props.location?.state?.backTo ?? '/tests'} />;
    return (
      <Container style={{ paddingTop: '20px', paddingBottom: '30px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}
        <div style={{ marginTop: this.props.location?.state?.backTo != null ? '-30px' : '0px' }}><BackToButton to='/tests/new' description='Start New Test' /></div>

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.2em' }}>{this.state.test?.getTestType().getName()} Results</Typography>
        {this.state.test?.getAnimal() != null &&
        <Typography variant='subtitle1' style={{ textAlign: 'center', marginBottom: this.state.test?.getTestType()?.getIsDemo() ? '0em' : '2em', fontSize: '20px', fontWeight: 'bold', color: 'slategrey' }}>
          Patient: {this.state.test.getAnimal()?.getName()}
        </Typography>}
        {this.state.test?.getTestType()?.getIsDemo() && <Typography variant='body1' style={{ color: 'red', fontWeight: 'bold', textAlign: 'center', marginBottom: '2em' }}>TRAINING TEST: CAPTURED IMAGE USED FROM PREVIOUS SAMPLE</Typography>}

        <>
          {/* Large Image */}
          {this.state.resultsTests?.map((test, index) => (
            <div key={index} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {/* NORMAL SAMPLE CHAMBER */}
              {!test?.getTestType()?.getUseVideo() && test?.getTestType()?.getType() === 'SAMPLE_CHAMBER' && (
                test?.getMaskedCircledImagePath() != null ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                      src={test?.getMaskedCircledImagePath()}
                      onClick={() => this.setState({ imageViewerSource: test?.getMaskedCircledImagePath() })}
                      width='90%'
                      style={{ margin: '2px' }}
                      alt=""
                      onError={(error) => console.log(error)}
                    />
                    <Typography variant='body1' style={{ color: 'slategrey', textAlign: 'center' }}>Click image for full screen.</Typography>
                  </div>
                ) : (
                  <Typography variant='body1' style={{ color: 'slategrey', fontStyle: 'italic' }}>No image available</Typography>
                )
              )}

              {/* VIDEO SAMPLE CHAMBER */}
              {test?.getTestType()?.getUseVideo() && (
                test?.getProcessedVideoPath() != null ? (
                  <video
                    controls
                    width='90%'
                    style={{ margin: '2px' }}
                    autoPlay
                    loop
                  >
                    <source src={test?.getProcessedVideoPath()} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <Typography variant='body1' style={{ color: 'slategrey', fontStyle: 'italic' }}>No video available</Typography>
                )
              )}

              {/* LATERAL_FLOW */}
              {test?.getTestType()?.getType() === 'LATERAL_FLOW' && (
                test?.getProcessedImagePath() != null ? (
                  <img
                    src={test?.getProcessedImagePath()}
                    onClick={() => this.setState({ imageViewerSource: test?.getProcessedImagePath() })}
                    width='90%'
                    style={{ margin: '2px' }}
                    alt=""
                  />
                ) : (
                  <Typography variant='body1' style={{ color: 'slategrey', fontStyle: 'italic' }}>No image available</Typography>
                )
              )}
            </div>
          ))}

          {this.state.imageViewerSource != null && (
            <ImageViewer
              src={[ this.state.imageViewerSource ]}
              currentIndex={ 0 }
              disableScroll
              closeOnClickOutside={ true }
              onClose={() => this.setState({ imageViewerSource: null })}
              backgroundStyle={{ backgroundColor: 'grey' }}
            />
          )}

          {this.state.isOtherImageViewerOpen && (
            <ImageViewer
              src={this.state.inViewFoundParasites.map(foundParasite => foundParasite.getImageUrl())}
              currentIndex={ 0 }
              disableScroll
              closeOnClickOutside={ true }
              onClose={() => this.setState({ isOtherImageViewerOpen: false, inViewFoundParasites: [] })}
              backgroundStyle={{ backgroundColor: 'grey' }}
            />
          )}

          <Typography variant='h2' style={{ marginTop: '2em' }}>{this.state.test?.getTestType()?.getType() === 'SAMPLE_CHAMBER' && !this.state.test?.getTestType()?.getUseVideo() ? 'Quantitative' : 'Qualitative'} Results</Typography>
          <>
            <div style={{ marginTop: '1em' }}>
              {this.state.resultsTests?.map(test => test.getTestedParasites()?.map(testedParasite => (
                <div key={testedParasite.getId()}>
                  <Typography variant='body1' style={{ marginTop: '0.5em' }}>
                    <b>{testedParasite.getParasiteSpecies()?.getName()}</b>:{' '}

                    {/* NORMAL SAMPLE CHAMBER */}
                    {test.getTestType()?.getType() === 'SAMPLE_CHAMBER' && !test.getTestType()?.getUseVideo() && (
                      test.getTestType().showEpg() ? (
                        testedParasite.getCount() + ' EPG'
                      ) : (
                        testedParasite.getCount() > 0 ? 'positive' : 'negative'
                      )
                    ) + (
                      test.getTestType()?.getShowEggsCounted() ? ' (' + testedParasite.getCount() + ' eggs counted)' : ''
                    )}
                  </Typography>

                  {/* VIDEO SAMPLE CHAMBER OR LATERAL FLOW */}
                  {(test.getTestType()?.getUseVideo() || test.getTestType()?.getType() === 'LATERAL_FLOW') && (
                    <Typography variant='body1' style={{ fontSize: '35px', color: 'red', fontWeight: 'bold' }}>
                      {testedParasite.getQualitativeResult()}
                    </Typography>
                  )}
                </div>
              )))}
            </div>

            <div style={{ marginTop: '1em' }}>
              {this.state.resultsTests?.map(test => test.getTestedParasites()?.map(testedParasite => testedParasite.getFoundParasites()?.length > 0 ? (
                <Accordion key={testedParasite.getId()} defaultExpanded elevation={10} style={{ marginTop: '0.5em' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ fontWeight: 'bold' }}>{testedParasite.getParasiteSpecies()?.getName()} Stained Images</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {testedParasite.getFoundParasites()?.map(foundParasite => (
                      <img
                        key={foundParasite.getId()}
                        src={ foundParasite.getImageUrl() }
                        onClick={() => {} /*this.setState({ isOtherImageViewerOpen: true, inViewFoundParasites: testedParasite.getFoundParasites() })*/}
                        width="300"
                        style={{ margin: '2px', width: '30%' }}
                        alt=""
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : null))}
            </div>
          </>

          <Typography variant='h2' style={{ marginTop: '2em' }}>Test Overview</Typography>

          <LineItem
            description='Date/Time'
            value={this.state.test?.getTimestamp()?.toLocaleString({ ...DateTime.DATETIME_MED, timeZoneName: 'short', timeZone: this.state.test?.getPractice()?.getAddress()?.getTimeZone() ?? undefined })}
          />

          <LineItem
            description='Patient'
            value={`${this.state.test?.getAnimal().getName()} (${this.state.test?.getAnimal().getAnimalSpecies().getName()})`}
          />

          <LineItem
            description='Patient Age at Time of Test'
            value={this.state.test?.getAnimalAge()}
          />

          <LineItem
            description='Patient Owner'
            value={`${this.state.test?.getAnimal().getOwnerFirstName()} ${this.state.test?.getAnimal().getOwnerLastName()}${this.state.test?.getAnimal().getOwnerEmail() != null ? ` (${this.state.test?.getAnimal().getOwnerEmail()})` : ''}`}
          />

          <LineItem
            description='Veterinarian'
            value={`${this.state.test?.getVeterinarian()?.getFullName()} (${this.state.test?.getVeterinarian()?.getEmail()})`}
          />

          {this.state.test?.getLabTech() != null &&
          <LineItem
            description='Lab Tech'
            value={`${this.state.test?.getLabTech()?.getFullName()} (${this.state.test?.getLabTech()?.getEmail()})`}
          />}

          {this.state.test?.getSampleName() != null &&
          <LineItem
            description='Sample Name'
            value={this.state.test.getSampleName()}
          />}

          {this.state.test?.getComments() != null &&
          <LineItem
            description='Comments'
            value={this.state.test.getComments()}
          />}

          <LineItem
            description='Reduction Test'
            value={this.state.test?.getIsReductionTest() ? 'Yes' : 'No'}
          />

          <LineItem
            description='Instrument Serial Number'
            value={this.state.test?.getInstrumentSerialNumber()}
          />

          <LineItem
            description='Test ID'
            value={this.state.test?.getId()}
          />

          <Typography variant='h2' style={{ marginTop: '2em', marginBottom: '0.5em' }}>Recommendations and Notes</Typography>
          {this.state.test?.getTreatments()?.length === 0 || this.state.inEditRecommendations ? (
            <>
              <form onSubmit={this.onSubmitRecommendations}>
                <Autocomplete
                  multiple
                  disablePortal
                  name='selectedTreatments'
                  options={this.state.treatments}
                  getOptionLabel={(option) => option.getName()}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0, height: '50px' } }} {...props}>
                      {option.getImageUrl() != null &&
                      <img
                        loading="lazy"
                        width="30"
                        src={option.getImageUrl()}
                        alt=""
                      />}
                      {option.getName()}
                    </Box>
                  )}
                  style={{ marginRight: '0.5em', marginBottom: '0.5em' }}
                  value={this.state.selectedTreatments}
                  onChange={(_, selectedTreatments) => this.setState({ selectedTreatments })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label='Select Treatments'
                      variant='filled'
                      inputProps={{
                        ...params.inputProps,
                        required: this.state.selectedTreatments.length === 0
                      }}
                      disabled={this.state.isLoading}
                    />
                  )}
                />

                <TextField
                  name='notes'
                  label='Notes'
                  fullWidth
                  multiline
                  rows={3}
                  style={{ marginTop: '0.5em' }}
                  value={this.state.notes}
                  onChange={this.onChange}
                  variant='filled'
                  disabled={this.state.isLoading}
                />

                {this.state.test?.getAnimal()?.getOwnerEmail() != null &&
                <div style={{ height: '3.5em', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <FormControlLabel style={{ marginBottom: '0.5em' }}
                    control={<Checkbox />}
                    onChange={() => this.setState({ sendResultsToOwner: !this.state.sendResultsToOwner })}
                    name='sendResultsToOwner'
                    label="Send results via email to animal's owner"
                    checked={this.state.sendResultsToOwner}
                    disabled={this.state.isLoading}
                  />
                </div>}

                <Typography variant='button1' style={{ color: 'grey' }}>Results will be emailed to veterinarian and lab tech on file.</Typography>

                <Button type='submit' isLoading={this.state.isLoading} style={{ width: '100%', marginTop: '1em' }}>
                  Submit
                </Button>
              </form>
            </>
          ) : (
            <>
              {this.state.test?.getWasModified() &&
              <Typography variant='body1' style={{ color: 'red', fontStyle: 'italic' }}>The test recommendations have been modified.</Typography>}

              <LineItem
                value={this.state.test?.getTreatments()?.map(treatment => treatment.getName())?.join(', ')}
                description={'Recommended Treatments'}
              />

              <LineItem
                value={this.state.test?.getNotes()}
                description={'Notes'}
              />

              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Button
                  secondary
                  onClick={this.openEditRecommendations}
                >
                  Edit Recommendations
                </Button>
                <Button
                  secondary
                  onClick={this.onSendToPims}
                  sx={{ marginTop: '20px' }}
                  isLoading={this.state.isLoadingPimsSend}
                >
                  Send Results to PIMS
                </Button>
                {this.state.test?.getPdfResultsUrl() && !cookies.isInstrumentSet() &&
                  <Button
                    sx={{ marginTop: '20px' }}
                    secondary
                    startIcon={<CloudDownloadIcon />}
                    onClick={() => window.open(this.state.test?.getPdfResultsUrl(), '_blank')}
                  >
                    Download PDF Results
                  </Button>}
              </Box>
            </>
          )}
        </>
      </Container>
    );
  }
}

Test.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(Test);
